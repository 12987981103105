<template>
  <div>
    <c-search-box @enter="getSearchList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="VENDOR_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="vendorClassCd"
            label="업체분류"
            v-model="searchParam.vendorClassCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="VENDOR_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="vendorTypeCd"
            label="업체유형"
            v-model="searchParam.vendorTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="vendorTable"
          title="수급업체 목록"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable"
                label="엑셀 업로드"
                icon="upload"
                @btnClicked="excelUploadData" />
              <c-btn label="검색" icon="search" @btnClicked="getSearchList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <c-tab
          type="tabcard"
          :tabItems.sync="tabItems"
          :inlineLabel="true"
          v-model="tab"
          align="left"
        >
          <template v-slot:default="tab">
            <component v-if="tab.name=='vendorDetail'"
              ref="vendorDetail"
              :is="tab.component"
              @searchDetail="getVendorList"
              @isNew="reset"
              :parentVendorCd="selectedVendorCd"
            />
            <component v-else-if="tab.name=='vendorUser'"
              ref="vendorUser"
              :is="tab.component"
              @searchDetail="getVendorList"
              @isNew="reset"
              :parentVendorCd="selectedVendorCd"
            />
            <component v-else-if="tab.name=='vendorRelatedAttach'"
              ref="vendorRelatedAttach"
              :is="tab.component"
              @searchDetail="getVendorList"
              @isNew="reset"
              :parentVendorCd="selectedVendorCd"
            />
          </template>
        </c-tab>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'vendor-manage',
  data() {
    return {
      searchParam: {
        vendorTypeCd: null,
        plantCd: null,
        vendorClassCd: null,
        vendorName: '',
        useFlag: 'Y',
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      useFlagItems: [],
      tab: 'vendorDetail',
      tabItems: [
        { name: 'vendorDetail', icon: 'list', label: '상세정보', component: () => import(`${'./vendorDetail.vue'}`) },
        { name: 'vendorUser', icon: 'info', label: '근무인원', component: () => import(`${'./vendorUser.vue'}`) },
        { name: 'vendorRelatedAttach', icon: 'folder', label: '관련서류', component: () => import(`${'./vendorRelatedAttach.vue'}`) },
      ],
      grid: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '업체',
            align: 'left',
            sortable: true,
            style: 'width:130px',
          },
          {
            name: 'vendorClassName',
            field: 'vendorClassName',
            label: '업체분류',
            align: 'center',
            style: 'width:70px',
            sortable: true,
          },
          {
            name: 'vendorTypeName',
            field: 'vendorTypeName',
            label: '업체유형',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'plantNames',
            field: 'plantNames',
            label: '관련사업장',
            align: 'left',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      selectedVendorCd: '',
      selectedVendor: [],
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.cim.vendor.list.url;
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getSearchList() {
      this.getList();
      this.reset();
    },
    getVendorList() {
      this.getList();
      if (this.selectedVendorCd == '') {
        this.reset();
      }
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        if (this.selectedVendorCd != '') {
          this.$refs["vendorDetail"].rowClick({ vendorCd: this.selectedVendorCd });
        } else {
          if (this.$refs["vendorDetail"] !== undefined) {
            this.$refs["vendorDetail"].reset();
          }
        }
      },);
    },
    rowClick(row) {
      this.selectedVendor = row;
      this.selectedVendorCd = row.vendorCd;
    },
    reset() {
      this.rowRemoveSelect();
      this.selectedVendorCd = '';
      this.selectedVendor = [];
    },
    excelUploadData() {
      this.popupOptions.title = '업체마스터 업로드';
      this.popupOptions.target = () => import(`${'./vendorManageExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          item.SysRevision = 1;
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
        })

        this.$http.url = transactionConfig.mdm.cim.vendor.insert.url + '/excel';
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    },
  }
};
</script>
